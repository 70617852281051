var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    { staticClass: "tui tuim ui form card-form" },
    [
      !_vm.isLoading
        ? _c("t-card-personal")
        : _c("t-card-placeholder", {
            attrs: { title: "Dados Pessoais", placeholder: 6 }
          }),
      !_vm.isLoading
        ? _c("t-card-contact")
        : _c("t-card-placeholder", {
            attrs: { title: "Contato", placeholder: 6 }
          }),
      !_vm.isLoading
        ? _c("t-card-address")
        : _c("t-card-placeholder", {
            attrs: { title: "Endereço", placeholder: 6 }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }