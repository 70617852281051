<template>
  <form class="tui tuim ui form card-form">
    <t-card-personal v-if="!isLoading" />
    <t-card-placeholder v-else :title="'Dados Pessoais'" :placeholder="6" />

    <t-card-contact v-if="!isLoading" />
    <t-card-placeholder v-else :title="'Contato'" :placeholder="6" />

    <t-card-address v-if="!isLoading"/>
    <t-card-placeholder v-else :title="'Endereço'" :placeholder="6" />
  </form>
</template>

<script>
import { mapState } from 'vuex'

export default {

  components: {
    TCardPersonal: () => import('./cards/personal.vue'),
    TCardContact: () => import('./cards/contact.vue'),
    TCardAddress: () => import('./cards/address.vue'),
    TCardPlaceholder: () => import('@/components/card-placeholder')
  },

  computed: {
    ...mapState({
      isLoading: ({ donors }) => donors.loadingDetail,
    }),
  }

}
</script>

<style lang="scss" >
@import '@/assets/sass/colors';
@import '@/assets/sass/card-form';

.tui.tuim.ui.form.card-form {
  .field {
    .wrap-form-inline {
      display: flex;
      flex-direction: row;

      .ui.selection.dropdown,
      .ui.fluid.dropdown {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        transition: all 500ms ease-in;
        font-size: 16px;
        line-height: 23px;
        max-height: 44px;
        min-height: 44px;
        padding-top: 10px;
        padding-right: 14px;
        border-right: none;

        .icon {
          width: 20px;
          height: 40px;
        }
      }

      .input {
        &.right {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }

        &.left {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;

          &:not(:focus) {
            border-right: none;
          }
        }
      }


    }

    &.disabled .wrap-form-inline .ui.input.with-selection {
      margin-left: -40px;
    }

    .number {
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        appearance: none;
      }
    }
  }

  .tui.tuim.ui.fluid.placeholder {
    margin-bottom: 20px;
  }
}
</style>
